<template>
    <div class="receive">
        <img v-bind:src="qrCodeLink" v-bind:alt="address">
        <g-copy class="address" v-bind:text="address"/>
    </div>

</template>

<script>

import { computed } from 'vue';

export default {
    name: 'Receive',
    components: {},
    props: ['address'],

    setup(props) {
        const qrCodeLink = computed(() => {
            return `https://qrcode.tec-it.com/API/QRCode?data=${props.address}`;
        });

        return {
            qrCodeLink
        };
    }
};
</script>

<style scoped lang="scss">
.receive {
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;

    & > img {
        width: 100%;
        max-width: 285px;
        height: auto;
        padding: 20px;
        background-color: white;
        border-radius: var(--bar-rounding, $bar-rounding);
    }

    & > .address {
        width: 100%;
        max-width: 300px;
        font-size: 13px;
    }
}
</style>